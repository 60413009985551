import React, { Component } from 'react';
import { BasicCard, ListCard, SimpleText, SimpleGrid } from './Balloon/AnswerBalloon';
import CarouselBalloon from './Balloon/CarouselBalloon';
//import QuickReplies from './QuickReplies';
import QuickRepliesSelect from './QuickRepliesSelect';
import { getTalkTime } from '../utils/utils';
import { getTalkTimeEn } from '../utils/utils';
class BotMessages extends Component {
	shouldComponentUpdate(nextProps, nextState) {
		return nextProps === this.props;
	}

	render() {
		/*
		<div className="menual_area"></div>
		*/

		let isCarousel = false;
		for (let i = 0; i < this.props.data.length; i++) {
			//console.debug(this.props.data[i]);

			if (this.props.data[i].carousel) {
				if (this.props.data[i].carousel.items.length > 1) {
					isCarousel = true;
					break;
				}
			}
		}

		let BotMessages = this.props.data.map((message, i) =>
			message.carousel ? (
				<CarouselBalloon key={i} data={message.carousel} isCarousel={isCarousel} />
			) : message.simpleText ? (
				<SimpleText key={i} data={message.simpleText} isGreeting={this.props.isGreeting} isCarousel={isCarousel} />
			) : message.basicCard ? (
				<BasicCard key={i} data={message.basicCard} isCarousel={isCarousel} />
			) : message.listCard ? (
				<ListCard key={i} data={message.listCard} />
			) : (
				message.simpleGrid && <SimpleGrid key={i} data={message.simpleGrid} />
			),
		);

		let inStyle;
		if (isCarousel) {
			inStyle = {
				width: '100%',
			};
		}

		return (
			<React.Fragment>
				<div className="bot_wrap" style={inStyle}>
					<span className="ico_comm ico_chatbot"></span>
					<span className="bot_name">{this.props.uiTexts.text_00}</span>
					<div className="bot_flex">
					{BotMessages}
					{this.props.quickBtns !== undefined && this.props.quickBtns !== null && (
						<QuickRepliesSelect data={this.props.quickBtns} uiTexts={this.props.uiTexts} />
					)}
					</div>
					<span className="time slide">{this.props.lang != 'en' ? getTalkTime() : getTalkTimeEn()}</span>
				</div>
			</React.Fragment>
		);
	}
}

export default BotMessages;
