import React, { PureComponent } from 'react';
class ChatTop extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClickSideMenu = this.handleClickSideMenu.bind(this);
		this.handleClickFeedback = this.handleClickFeedback.bind(this);

		this.state = {
			lang_is_active: false,
		};
	}

	handleClickSideMenu() {
		this.props.openSideMenu();
	}

	handleClickFeedback() {
		if (this.props.init === false) return;

		if (this.props.isLogin) {
			this.props.openFeedback();
		} else {
			this.props.controlOverlay({ toggle: true, closeAll: false });
			this.props.loginAlert();
		}
	}

	clickChatbotClose() {
		try {
			window.closeChatbot(); //App에서만 동작하는 코드
		} catch (error) {
			console.error(error);
		}
	}

	settingLang(lang) {
		this.props.setLang(lang);
		this.toggleSelect();
	}

	toggleSelect() {
		if (this.state.lang_is_active) {
			this.setState({
				lang_is_active: false,
			});
			this.langSelect.className = 'btn_language';
		} else {
			this.setState({
				lang_is_active: true,
			});
			this.langSelect.className = 'btn_language is_active';
		}
	}

	render() {
		//console.debug(this.props.theme);
				
		return (
			
			<div className="chat_header">
			<h1 className="h_tit">
				<span className={this.props.lang !== 'en' ? 'ico_comm ico_bot_logo' : 'ico_comm ico_bot_logo_en'}></span>
			</h1>
			{ (this.props.config.projectId == 16 || this.props.config.projectId == 14) &&
				<div className="language_box">
					<button
						type="button"
						className="btn_language"
						onClick={() => {
							this.toggleSelect();
						}}
						ref={(ref) => {
							this.langSelect = ref;
						}}
					>
						
						<span className={this.props.theme !== 'c1' ? 'ico_comm ico_language' : 'ico_comm ico_language1'}>
							다른 언어 선택 목록 보기
						</span>
					</button>
					<div className="language_inner_box">
						<h2 className="screen_out">언어선택</h2>
						<ul className="language_list">
							{this.props.lang === 'ko' ? (
								<li className="language_item is_selected">
									<a href="./index.html?projectId=14&lang=ko" className="language_link">
										KR
									</a>
								</li>
							) : (
								<li className="language_item">
									<a href="./index.html?projectId=14&lang=ko" className="language_link">
										KR
									</a>
								</li>
							)}
							{this.props.lang === 'en' ? (
								<li className="language_item is_selected">
									<a href="./index.html?projectId=16&lang=en" className="language_link">
										EN
									</a>
								</li>
							) : (
								<li className="language_item">
									<a href="./index.html?projectId=16&lang=en" className="language_link">
										EN
									</a>
								</li>
							)}
						</ul>
					</div>
				</div>
			}

					
				<div className="btn_unit">
					<button type="button" className="menu_btn btn_menu" onClick={this.handleClickSideMenu}>
						<span className={this.props.theme !== 'c1' ? 'ico_comm ico_menu' : 'ico_comm ico_menu1'}>메뉴보기</span>
					</button>
				</div>
			</div>
		);
	}
}

export default ChatTop;
