let APP_CONFIG;

if (process.env.NODE_ENV === 'production') {
	// real server config
	
	APP_CONFIG = {
		channelCode: 'DEFAULT',
		chatbotUrl: 'https://chat.ewha.ac.kr',
		getGreeting: '/message/greeting',
		sendQuestion: '/message/send',
		sendTrigger: '/message/trigger',
		sendPostback: '/message/postback',
		getPush: '/message/getPush',
		getArk: '/ark',
		getRealtimeAnswer: '/realTimeAnswer',
		writeFeedback: '/feedback/write',
		logIn: 'https://chat.ewha.ac.kr/swift/sso/requestLogin.jsp?retURL=%2Findex.jsp&entity_id=sso.ewha.ac.kr',
		//logOut: 'https://chat.ewha.ac.kr/swift/sso/requestStdLogout.jsp', //20220207 수정하기 이전 버전(sso 수정)
		logOut: 'https://chat.ewha.ac.kr/swift/sso/swiftLogOutReq.jsp?RSP=chat.ewha.ac.kr', //한번에로그아웃은됨 but 로그아웃이슈 > 202
		//logOut: 'http://sso.ewha.ac.kr/SSO_IDP/swift/sso/swiftLogOut.jsp?RSP=chat.ewha.ac.kr', //2번에 로그아웃됨 but sso-로그아웃이슈x
		//logOut : 'https://chat.ewha.ac.kr/swift/sso/requestStdLogout.jsp?retURL=/chatbot/index.html', //한번에로그아웃o 로그아웃이슈o
		getSetting: '/setting/get',
		setSetting: '/setting/set',
		popWinTarget: '_blank',
		maxSetting: 4,
		privacyLink: '',
		useLogin: false,
		timeout: 30000,
	};
	
	// dev server config
	/*
	APP_CONFIG = {
		channelCode: 'DEFAULT',
		chatbotUrl: '/ichat_api_dev',
		getGreeting: '/message/greeting',
		sendQuestion: '/message/send',
		sendTrigger: '/message/trigger',
		sendPush: '/message/getPush',
		sendPostback: '/message/postback',
		getArk: '/ark',
		getRealtimeAnswer: '/realTimeAnswer',
		writeFeedback: '/feedback/write',
		logIn: 'http://ssodev.ewha.ac.kr/SSO_IDP/swift/sso/loginForm.jsp?RSP=chatdev.ewha.ac.kr_ichat_api_dev&RelayState=%2Findex.jsp',
		logOut: 'https://chat.ewha.ac.kr/swift/sso/requestStdLogout.jsp',
		getSetting: '/setting/get',
		setSetting: '/setting/set',
		popWinTarget: '_blank',
		maxSetting: 5,
		privacyLink: '',
		useLogin: false, //true:로그인 해야지만 서비스 이용가능, false:로그인 하지 않아도 질의응답 가능.
		timeout: 10000,
	}
	*/
} else {
	APP_CONFIG = {
		channelCode: 'DEFAULT',
		chatbotUrl: 'https://chat.ewha.ac.kr/',
		getGreeting: '/message/greeting',
		sendQuestion: '/message/send',
		sendTrigger: '/message/trigger',
		sendPostback: '/message/postback',
		getPush: '/message/getPush',
		getArk: '/ark',
		getRealtimeAnswer: '/realTimeAnswer',
		writeFeedback: '/feedback/write',
		logIn: 'http://ssodev.ewha.ac.kr/SSO_IDP/swift/sso/loginForm.jsp?RSP=chatdev.ewha.ac.kr_ichat_api_dev&RelayState=%2Findex.jsp',
		logOut: 'http://chatdev.ewha.ac.kr:8080/ichat_api_dev/swift/sso/requestStdLogout.jsp',
		getSetting: '/setting/get',
		setSetting: '/setting/set',
		popWinTarget: '_blank',
		maxSetting: 4,
		privacyLink: '',
		useLogin: false,
		timeout: 30000,
	};
}

const NEED_LOGIN_TEMPLATE = {
	outputs: [
		{
			simpleText: {
				text: '채티(Chat-E)를 이용하려면 로그인이 필요해요. 😃<br/><br/>로그인 후 다시 질문 해 줄래요?',
				buttons: [
					{
						label: '로그인 하기',
						action: 'webLink',
						webLinkUrl: '',
						target: '_self',
					},
				],
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_GREETING_TEMPLATE = {
	outputs: [
		{
			simpleText: {
				text: '안녕하세요 채티(Chat-E)입니다. 🙂',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_SETTING = {
	data: {
		isLogin: false,
		useArk: true,
		menuPosition: 'right',
		greeting: [],
		quickMenu: [
			{
				isUse: '1',
				id: 21.0,
				label: '학사일정',
				utterance: '학사일정 알려주세요.',
			},
			{
				isUse: '1',
				id: 22.0,
				label: '셔틀버스',
				utterance: '셔틀버스 이용에 대해 안내해주세요.',
			},
			{
				isUse: '0',
				id: 23.0,
				label: '캠퍼스맵',
				utterance: '이화여자대학교 캠퍼스맵 보여주세요.',
			},
			{
				isUse: '0',
				id: 24.0,
				label: '학식메뉴',
				utterance: '식당에 대해 알려주세요.',
			},
			{
				isUse: '1',
				id: 25.0,
				label: '장학일정',
				utterance: '장학금 일정에 대해 알려주세요.',
			},
			{
				isUse: '1',
				id: 26.0,
				label: '교내연락처',
				utterance: '본교 대표 전화번호 알려주세요.',
			},
			{
				isUse: '1',
				id: 27.0,
				label: '도서관이용',
				utterance: '중앙도서관 이용정보를 알고 싶어요.',
			},
		],
		theme: '',
		state: {
			code: 0,
			detail: 'SUCCESS',
		},
	},
};

const DEFAULT_ERROR = {
	outputs: [
		{
			simpleText: {
				text: '앗, 챗봇 서비스 점검 중이네요😢 잠시 후에 다시 질문해줄래요?',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_ERROR_EN = {
	outputs: [
		{
			simpleText: {
				text: 'The chatbot service is undergoing maintenance😢 Please try again later.',
			},
		},
	],
	quickReplies: null,
};

const DEFAULT_ERROR_CN = {
	outputs: [
		{
			simpleText: {
				text: 'chatbot服务正在维护中。😢 请稍后再试一下。',
			},
		},
	],
	quickReplies: null,
};

export { APP_CONFIG, NEED_LOGIN_TEMPLATE, DEFAULT_GREETING_TEMPLATE, DEFAULT_SETTING, DEFAULT_ERROR, DEFAULT_ERROR_EN, DEFAULT_ERROR_CN };
