import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as messageActions from '../modules/message';
import { APP_CONFIG } from '../utils/config';
import { openUrl, getLink, nl2br } from '../utils/utils';

import '../css/select.scss';

class QuickRepliesSelect extends PureComponent {
	send(value) {
		let sendData = {
			utterance: value,
			sessionKey: this.props.message.sessionKey,
			channelId: this.props.message.channelId,
			isLogin: this.props.message.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	openLink(url) {
		openUrl(url, APP_CONFIG.popWinTarget, this.props.fromApp);
	}

	click() {
		let value = this.select.options[this.select.selectedIndex].value;
		if (value !== '') {
			if (this.validURL(value) === true) {
				this.openLink(value);
			} else {
				this.send(value);
			}
		}
	}

	validURL(str) {
		var pattern = new RegExp('^(https?:\\/\\/)+');

		return pattern.test(str);
	}

	render() {
		let messageText = '';

		for (let i = 0; i < this.props.data.length; i++) {
			if (this.props.data[i].action === 'message' && this.props.data[i].label === '{TEXT}') {
				messageText = this.props.data[i].messageText
				break;
			}
		}

		const quickReplies = this.props.data.map((item, index) =>
			item.action === 'webLink' ? (
				<option key={index} value={getLink(item.webLinkUrl)}>
					{item.label}
				</option>
			) : (
				item.action === 'message' && item.label !== '{TEXT}' &&(
					<option key={index} value={item.messageText}>
						{item.label}
					</option>
				)
			),
		);
		
		/*
		<button
			className="btn_sel_ok"
			onClick={() => {
				this.click();
			}}
		>
			{this.props.uiTexts.text_35}
		</button>

		<div style={wordBreak} dangerouslySetInnerHTML={{ __html: nl2br(this.props.data.text) }} />
		*/
		let inStyle = {
				width: '236px',
				minWidth: '236px'
			};
		let wordBreak = {
			wordBreak: "break-word",
			paddingBottom: "15px"
		}
		return (
			<div className="bot_box">
			<div className="bot_txt"  style={inStyle}>
				<div style={wordBreak} dangerouslySetInnerHTML={{ __html: nl2br(messageText) }} />
				<div className="option_box">
					<div include="form-input-select()">
						<select
							required
							ref={(ref) => {
								this.select = ref;
							}}
							onChange={() => {
								this.click();
							}}
						>
							<option value="" hidden>
								{this.props.uiTexts.text_34}
							</option>
							{quickReplies}
						</select>
					</div>
				</div>
			</div>
		</div>
	
		);
	}
}
function mapStateToProps(state) {
	return { ...state };
}

function mapDispatchToProps(dispatch) {
	return { send: (payload) => dispatch(messageActions.sendMessage(payload)) };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickRepliesSelect);
