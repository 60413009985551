import { createAction } from 'redux-actions';
import update from 'react-addons-update';
import * as ActionTypes from './ActionTypes';

export const getSetting = createAction(ActionTypes.GET_SETTING.REQUEST);
export const setSetting = createAction(ActionTypes.SET_SETTING.REQUEST);

export const useArk = createAction(ActionTypes.USE_ARK);
export const menuPosition = createAction(ActionTypes.MENU_POSITION);

export const toggleGreeting = createAction(ActionTypes.TOGGLE_GREETING);
export const toggleQuickMenu = createAction(ActionTypes.TOGGLE_QUICKMENU);

export const setFavorite = createAction(ActionTypes.SET_FAVORITE);
export const setTheme = createAction(ActionTypes.SET_THEME);

const initialState = {};

function setting(state = initialState, action) {
	//console.info('[reducers.message.message][action]', action)
	switch (action.type) {
		case ActionTypes.GET_SETTING.SUCCESS:
			state = action.payload.response.data;	
			return {
				...state,
			};
		//return action.payload.response.data
		case ActionTypes.USE_ARK:
			return {
				...state,
				useArk: action.payload,
			};
		case ActionTypes.SET_THEME:
			state.theme = action.payload !== '' ? action.payload : '';
			return {
				...state,
				theme: action.payload !== '' ? action.payload : '',
			};
		case ActionTypes.MENU_POSITION:
			return {
				...state,
				menuPosition: action.payload,
			};
		case ActionTypes.TOGGLE_GREETING:
			return {
				...state,
				greeting: update(state.greeting, {
					[action.payload.target.value]: {
						isUse: { $set: action.payload.target.className === 'btn_control' ? '1' : '0' },
					},
				}),
			};
		case ActionTypes.SET_FAVORITE:
			let data;
			if (action.payload.action === 'add') {
				if (state.favorite === null) state.favorite = [];
				// console.log(state.favorite.indexOf(action.payload.value))
				if (state.favorite.indexOf(action.payload.value) === -1) {
					data = [...state.favorite, action.payload.value];
				} else {
					data = state.favorite;
				}
			}
			if (action.payload.action === 'del') {
				data = state.favorite.filter((value) => value !== action.payload.value);
			}
			return {
				...state,
				favorite: data,
			};
		case ActionTypes.TOGGLE_QUICKMENU:
			return {
				...state,
				quickMenu: update(state.quickMenu, {
					[action.payload.target.value]: {
						isUse: { $set: action.payload.target.className === 'btn_control' ? '1' : '0' },
					},
				}),
			};
		default:
			return state;
	}
}

export default setting;
