/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { PureComponent } from 'react';
import { APP_CONFIG } from '../../utils/config';

class SideMenu extends PureComponent {
	constructor(props) {
		super(props);

		this.handleClickClose = this.handleClickClose.bind(this);
		this.handleClickTip = this.handleClickTip.bind(this);
		this.handleAlert = this.handleAlert.bind(this);
		this.handleClickSetting = this.handleClickSetting.bind(this);
		this.handleQuickMenuClick = this.handleQuickMenuClick.bind(this);
		//this.handleClickLoginAlert = this.handleClickLoginAlert.bind(this)
	}

	componentDidMount() { 
		let quickMenuLis = document.getElementsByClassName('sub_menu_item')
		if (quickMenuLis.length > APP_CONFIG.maxSetting) {
			for (let i = 0; i < quickMenuLis.length; i++) {
				if (i > APP_CONFIG.maxSetting-1) quickMenuLis[i].style.display = "none";
			}
		}
	}

	state = {
		isOpenTheme: false,
	};

	handleQuickMenuClick(e) {
		// this.totalClose()
		this.props.close();
		let sendData = {
			utterance: e.target.value,
			sessionKey: this.props.sessionKey,
			channelId: this.props.channelId,
			isLogin: this.props.isLogin,
			isDev: this.props.config.isDev,
			projectId: this.props.config.projectId,
		};

		this.props.send(sendData);
	}

	handleClickClose() {
		// this.totalClose()
		if (this.props.isLogin) {
			this.props.setSetting();
		}
		this.props.close();
	}


    handleClickTip() {
        // this.totalClose()
		/*
        this.props.close()

        let sendData = {
            "utterance" : '이용팁', 
            "sessionKey" : this.props.sessionKey,
            "channelId" : this.props.channelId,
            "isLogin" : this.props.isLogin
        }

        this.props.send(sendData)
		*/
		this.props.openUseTip();
    }


	handleClickSetting() {
		//if (this.props.isLogin) {
			this.props.openSetting();
			/*
		} else {
			this.props.loginAlert();
		}
		*/
	}

	handleAlert() {
		alert('준비중입니다.');
	}

	clickPosition(value) {
		this.props.setMenuPostion(value);
	}

	openThemeSet() {
		const currentState = this.themeSetDiv.style.display;
		//console.debug(currentState);
		if (currentState === '' || currentState === 'none') {
			this.themeSetDiv.style.display = 'block';
			this.setState({ isOpenTheme: true });
		} else {
			this.themeSetDiv.style.display = 'none';
			this.setState({ isOpenTheme: false });
		}
	}
	/*
    <a href="#" className="top_menu fL"><img src="img/ico_close.png" alt="닫기" /></a>
    <a href="#" className="btn_logout left"><i className="fas fa-sign-out-alt"></i><span>로그아웃</span></a>
    <a href="#" className="btn_login left"><i className="fas fa-user-circle mR5"></i>로그인</a>
    */

	render() {
		/*
		let topMenuClass = 'sub_menu_inner right';
		if (this.props.menuPosition === 'left') {
			topMenuClass = 'sub_menu_inner left';
		}
		let themeName = '파랑';
		if (this.props.theme === 'theme_green') themeName = '초록';
		if (this.props.theme === 'theme_purple') themeName = '보라';
		if (this.props.theme === 'theme_red') themeName = '빨강';
        */
	
		return (
			//<div className="sub_menu_box">
				<div className="sub_menu_inner" ref={(ref) => { this.sidemenuElement = ref;}}>
					<div className="profile_box">
						{this.props.config.projectId != 14 ? 
							(
							//프로젝트 ID가 14가아닌경우(SSO문제로 기본프로젝트만 로그인연동 )
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="./img/profile_before.png" alt="" />
								</span>
								<span className="profile_name">{this.props.uiTexts.text_23}</span>
							</React.Fragment>
							)
						: this.props.isLogin ? ( //로그인인 경우
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="./img/profile_before.png" alt="" />
								</span>
								<span className="profile_name">{this.props.userName}</span>
								<a href={APP_CONFIG.logOut} className="logout_link">
									<span className="ico_comm">{this.props.uiTexts.text_28}</span>
								</a>
							</React.Fragment>
						) : (
							//로그인이 아닌 경우
							
							<React.Fragment>
								<span className="chat_logo_box">
									<img src="./img/profile_before.png" alt="" />
								</span>
								<span className="profile_name">{this.props.uiTexts.text_23}</span>
								<a href={APP_CONFIG.logIn} className="btn_login">
									{this.props.uiTexts.text_24}
								</a>
							</React.Fragment>
						)}
					</div>
					{this.props.quickMenu && ( //퀵메뉴 표시
						<ul className="sub_menu_list">
							{this.props.quickMenu.map(
								(button, index) =>
									button.isUse === '1' && (
										<li className="sub_menu_item" key={index}>
											<button
												type="button"
												value={button.utterance}
												className="sub_menu_link"
												onClick={this.handleQuickMenuClick}
											>
												{button.label}
											</button>
										</li>
									),
							)}
						</ul>
					)}
					<div className="sub_btn_group">
						<ul className="setting_list">
							<li className="list_item">
								<div className="sub_btn">
									<span>{this.props.uiTexts.text_04}</span>
									<div className={'color_set ' + this.props.theme}></div>
									<span
										className={this.state.isOpenTheme ? 'ico_comm ico_arrow is_active' : 'ico_comm ico_arrow'}
										onClick={() => {
											this.openThemeSet();
										}}
									></span>
									<ul
										className="set_list"
										ref={(ref) => {
											this.themeSetDiv = ref;
										}}
									>
										<li>
											<a
												href="#"
												className="c1"
												onClick={() => {
													this.props.setTheme('c1');
													if (this.props.isLogin) {
														this.props.setSetting();
													}
												}}
											></a>
										</li>
										<li>
											<a
												href="#"
												className="c2"
												onClick={() => {
													this.props.setTheme('c2');
													if (this.props.isLogin) {
														this.props.setSetting();
													}
												}}
											></a>
										</li>
										<li>
											<a
												href="#"
												className="c3"
												onClick={() => {
													this.props.setTheme('c3');
													if (this.props.isLogin) {
														this.props.setSetting();
													}
												}}
											></a>
										</li>
									</ul>
								</div>
							</li>
							{this.props.isLogin && this.props.config.projectId == 14 && ( //로그인인 경우 설정을 표시하자
								<li className="list_item" >
									<div className="sub_btn" onClick={this.handleClickSetting}>
										<span>{this.props.uiTexts.text_02}</span>
										<button type="button">
											<span className="ico_setting"></span>
										</button>
									</div>
								</li>
							)
							}
							{
								(this.props.config.projectId == 14 || this.props.config.projectId == 16) && ( //한국어챗봇, 영어챗봇에만 이용안내 버튼 있도록 수정
									<li className="list_item">
										<div className="sub_btn" onClick={this.handleClickTip}>
											<span>{this.props.uiTexts.text_38}</span>
											<button type="button">
												<span className="ico_info"></span>
											</button>
										</div>
									</li>
								)
							}
						</ul>
					</div>

					<button type="button" className="btn_close" onClick={this.handleClickClose}>
						<span className="ico_comm ico_close">메뉴 레이어 닫기</span>
					</button>
				</div>
			//</div>
		);
	}
}

export default SideMenu;
